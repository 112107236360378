// useForm.ts
import { ref, Ref } from 'vue'
import { cloneDeep } from 'lodash'

export type UseFormOptions<T> = {
  onSubmit?: (values: T) => void;
  validate?: (values: T) => boolean;
};

export type UseFormInstance<T> = T & {
  submit: () => void;
  setForm: (form: T) => void;
  resetForm: () => void;
};

export type UseForm<T> = {
  form: Ref<UseFormInstance<T>>;
}

export const useForm = <T>(initialValues: T, options: UseFormOptions<T>): UseForm<T> => {
  const initialClone = cloneDeep(initialValues)

  const form: Ref<UseFormInstance<T>> = ref(cloneDeep(initialValues))

  form.value.submit = () => {
    if (options.validate && !options.validate({ ...form.value } as T)) {
      return
    }

    options.onSubmit({ ...form.value } as T)
  }

  form.value.setForm = (newForm: T) => {
    const newClone = cloneDeep(newForm)
    for (const key in newClone) {
      if (Object.prototype.hasOwnProperty.call(newClone, key)) {
        form[key] = newClone[key]
      }
    }
  }

  form.value.resetForm = () => {
    for (const key in initialClone) {
      if (Object.prototype.hasOwnProperty.call(initialClone, key)) {
        form.value[key] = initialClone[key]
      }
    }
  }

  return {
    form
  }
}
