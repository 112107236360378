<script setup lang="ts">
interface UserProfileFormRowProps {
  label?: string
}

const props = defineProps<UserProfileFormRowProps>();
</script>

<template>
  <label class="mb-10">
    <slot name="label">{{props.label}}</slot>
  </label>
  <div class="flex-wrap flex-nowrap align-items-center mb-15">
    <div class="col-2">
      <slot></slot>
    </div>
    <slot name="description"></slot>
  </div>
</template>

<style scoped>

</style>
