import {App as VueRuntimeApp, createApp} from 'vue'
import {createPinia, Pinia} from 'pinia'
import {AppContainerConfig} from "./types";
import ProfileSettings from "./Pages/Profile/ProfileSettings.vue";

const pinia: Pinia = createPinia();

const appContainerConfigs: AppContainerConfig[] = [
  {
    mountElementSelector: '#profile-settings-widget',
    rootComponent: ProfileSettings,
    initPropsFromMountElement: true
  }
]

// TODO: we need to use shared props to pass general user info and his roles to vue3. Then we can create user store with pinia and use it in all vue3 apps.
const initDataset = (appContainerConfig: AppContainerConfig) => {
  const mountElement = document.querySelector(appContainerConfig.mountElementSelector);

  if (!mountElement) {
    throw new Error(`Mount element not found: ${appContainerConfig.mountElementSelector}`);
  }

  let dataset: null | Record<string, any> = appContainerConfig.initPropsFromMountElement ?
    {...mountElement.dataset} :
    null;

  Object.keys(dataset ?? {}).forEach(key => {
    const value = dataset[key];

    if (typeof value === 'string') {
      dataset[key] = JSON.parse(dataset[key]);
    }
  })

  return dataset;
}

const applyCommonAppPlugins = (app: VueRuntimeApp): VueRuntimeApp => {
  app.use(pinia);

  return app;
}

const runtimeApps: VueRuntimeApp[] = [];

appContainerConfigs.forEach(appContainerConfig => {
  try {
    let app = createApp(
      appContainerConfig.rootComponent,
      initDataset(appContainerConfig)
    )

    applyCommonAppPlugins(app);

    app.mount(appContainerConfig.mountElementSelector)

    runtimeApps.push(app);
  } catch (e) {
    return;
  }
});
