<script setup lang="ts">
import {userHasRole} from "../../Services/User/Roles";
import {dateTransform} from "../../helpers/datetime";
import {UserRole} from "../../Services/Types";

interface UserRolesProps {
  userRoles: UserRole[]
}

const props = defineProps<UserRolesProps>();
</script>

<template>
  <div v-if="props.userRoles.length">
    Ви оплатили:
    <ul>
      <li v-for="role in props.userRoles" :key="role.id">
        {{ role.readable_name }} &mdash;
        <span>
          {{ role.granted_due ? `до ${dateTransform(role.granted_due)}` : 'назавжди' }}
        </span>
      </li>
    </ul>
  </div>
  <div v-else-if="userHasRole(props.userRoles,'demo_access')" class="text-primary alert alert-primary">
    <i class="icon-warning mr-5"></i>
    Активуйте <a href="/prices">повний доступ</a>, аби вільно користуватися розширеною аналітикою
    даних Prozorro та актуальними даними 140 реєстрів.
  </div>
  <div v-else-if="!userHasRole(props.userRoles, ('email_confirm')) || !userHasRole(props.userRoles, ('phone_confirm'))"
       class="text-primary alert alert-primary">
    <i class="icon-warning mr-5"></i>
    Для отримання демо-доступу до всіх можливостей Clarity Project залишився лише один крок &mdash;
    підтвердіть, будь ласка, свої дані нижче.
  </div>
  <div v-else class="text-primary alert alert-primary">
    <i class="icon-warning mr-5"></i>
    Вам надано демо-доступ до всіх можливостей Clarity на 24 години.
    Його можна <a href="/prices">активувати тут</a>.
  </div>
</template>

<style scoped>

</style>
