<script setup lang="ts">
import UserRoles from "../../components/User/UserRoles.vue";
import UserForm from "../../components/User/UserForm.vue";
import UserChangePasswordForm from "../../components/User/UserChangePasswordForm.vue";
import {storeToRefs} from "pinia";
import {useUserStore} from "../../stores/user/useUserStore";
import BigLoader from "../../components/Loader/BigLoader.vue";

const userStore = useUserStore();

const {user, userRoles, dataInitialized} = storeToRefs(userStore);
</script>

<template>
  <BigLoader :show-loader="!user"/>

  <template v-if="dataInitialized && user">
    <h2>
      <i class="icon-user"></i>
      {{ user.login }}
      &bull;
      ID: {{ user.id }}
    </h2>

    <UserRoles :user-roles="userRoles"/>

    <div class="mt-15 pt-15 border-top">
      Ви також можете <a href="/me/promocode" class="button button-sm btn-outline">застосувати
      промокод</a>.
    </div>

    <UserForm :user="user" :user-roles="userRoles"/>

    <UserChangePasswordForm/>
  </template>
</template>

<style scoped>

</style>
