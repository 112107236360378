<script setup lang="ts">
interface FormSubmitButtonProps {
  disabled: Pick<HTMLInputElement, 'disabled'>,
  value: Pick<HTMLInputElement, 'value'>,
}

const props = withDefaults(defineProps<FormSubmitButtonProps>(), {
  disabled: false,
  value: 'Зберегти',
});
</script>

<template>
  <input
    :disabled="props.disabled"
    type="submit"
    class="button btn-outline ml-0 mr-15"
    :value="props.value"
  />
</template>

<style scoped>

</style>
