<script setup lang="ts">

import { User, UserRole } from '../../Services/Types'
import { ref } from 'vue'
import { userHasRole } from '../../Services/User/Roles'
import UserEmailConfirm from './UserEmailConfirm.vue'
import Loader from '../Loader/Loader.vue'
import axios from 'axios'
import { routes } from '../../Services/Routes'
import { useUserStore } from '../../stores/user/useUserStore'
import { useForm } from '../Form/composables/useForm'
import FormInput from '../Form/FormInput.vue'
import FormSubmitButton from '../Form/FormSubmitButton.vue'
import UserProfileFormRow from './Form/UserProfileFormRow.vue'

interface UserFormProps {
  user: User,
  userRoles: UserRole[],
}

type FormData = Pick<User, 'name' | 'email' | 'phone'>;

const userStore = useUserStore()

const props = defineProps<UserFormProps>()

const { form } = useForm<FormData>({
  name: props.user.name,
  email: props.user.email,
  phone: props.user.phone
}, {
  onSubmit: (values) => {
    if (savingForm.value) {
      return
    }

    savingForm.value = true

    axios.postForm(routes.api.internal.user.update, values)
      .then(r => {
        const status = r.data.status || false
        const result = r.data.result || 'Дані оновлено'

        formResponse.value = {
          success: status,
          message: result
        }

        if (status) {
          userStore.loadData()
        }
      })
      .catch(() => {
        formResponse.value = {
          success: false,
          message: 'Помилка серверу. Будь ласка, спробуйте пізніше'
        }
      })
      .finally(() => {
        savingForm.value = false
      })
  }
})

const savingForm = ref(false)
const formResponse = ref<{
  success: boolean,
  message: string,
}>({
  success: false,
  message: ''
})
</script>

<template>
  <div class="mt-15 pt-15 border-top">
    <form @submit.prevent="form.submit()">
      <h5 class="mb-10">Ваші дані: </h5>

      <user-profile-form-row label="Ваше ім'я:">
        <form-input
          type="text"
          v-model="form.name"
        />
      </user-profile-form-row>

      <user-profile-form-row label="Ваш e-mail:">
        <form-input
          type="email"
          v-model="form.email"
        />

        <template #description>

          <UserEmailConfirm :user-roles="props.userRoles" :email="props.user.email" />
        </template>
      </user-profile-form-row>

      <user-profile-form-row label="Ваш телефон">
        <form-input
          type="text"
          v-model="form.phone"
          placeholder="+380"
        />

        <template #description>
          <div class="col-2 ml-15 small">
            <div v-if="!form.phone">
              Будь ласка, заповніть свій номер телефону
            </div>
            <div v-else-if="userHasRole(userRoles, 'phone_confirm')">
              <i class="icon-accept mr-5 text-primary"></i>
              Підтверджений.
            </div>
            <div v-else>
              <i class="icon-warning mr-5 text-warning"></i>
              Для підтвердження свого телефона, будь ласка,
              <a href="/me/confirm-phone">перейдіть сюди</a>.
            </div>
          </div>
        </template>
      </user-profile-form-row>

      <div class="submit-wrap">
        <form-submit-button
          :disabled="savingForm"
          value="Зберегти зміни"
        />

        <loader :show-loader="savingForm" />

        <span
          v-if="formResponse"
          :class="{
            'text-danger': !formResponse.success,
            'ml-15': true,
            'small': true,
          }"
        >
          {{ formResponse.message }}
        </span>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>
