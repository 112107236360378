<script setup lang="ts">
import {LoaderProps} from "./index";

const props = withDefaults(
  defineProps<LoaderProps>(),
  {
    showLoader: false,
  }
);
</script>

<template>
  <div v-if="props.showLoader">
    <div class="loading"><i></i><i></i><i></i></div>
    <span class="loader-text">
    <slot>
        Завантаження...
    </slot>
      </span>
  </div>
</template>

<style scoped>
.loader-text {
  font-size: 1.2rem;
  color: #000;
  margin-left: 10px;
}
</style>
