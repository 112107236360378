<script setup lang="ts">

import {LoaderProps} from "./index";

const props = withDefaults(
  defineProps<LoaderProps>(),
  {
    showLoader: false,
  }
);
</script>

<template>
  <div class="loading" v-if="props.showLoader"><i></i><i></i><i></i></div>
</template>

<style scoped>

</style>
