<script setup lang="ts">

interface FormInputProps {
  type: Pick<HTMLInputElement, 'type'>,
  placeholder: Pick<HTMLInputElement, 'placeholder'>,
  disabled: Pick<HTMLInputElement, 'disabled'>,
  accept: Pick<HTMLInputElement, 'accept'>,
  required: Pick<HTMLInputElement, 'required'>,
}

const props = withDefaults(defineProps<FormInputProps>(), {
  type: 'text',
  placeholder: '',
  disabled: false,
  required: false,
  accept: '',
}) as Required<FormInputProps>;

const model = defineModel({
  required: true,
});
</script>

<template>
  <input
    :type="props.type"
    :placeholder="props.placeholder"
    :disabled="props.disabled"
    :accept="props.accept"
    :required="props.required"
    class="form-control"
    v-model="model"
  />
</template>

<style scoped>
.form-control {
  margin-bottom: 0;
}
</style>
