import {UserRole} from "../../Types";

export const userHasRole = (userRoles: UserRole[], role: string): boolean => {
  return userRoles.some((userRole: UserRole) => {
    if (userRole.name !== role) {
      return false;
    }

    return userRole.is_active;
  })
}
