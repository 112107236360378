import {onBeforeMount, ref} from "vue";

export interface DataLoading {
  dataLoading: boolean
  dataInitialized: boolean,
  loadData: () => Promise
}

export const useDataLoading = (dataLoadCallback: () => Promise): DataLoading => {
  const dataLoading = ref(false);
  const dataInitialized = ref(false);

  const loadData = async () => {
    dataLoading.value = true;

    dataLoadCallback().finally(() => {
      dataLoading.value = false;
    });
  }

  const initializeData = () => {
    loadData().finally(() => {
      dataInitialized.value = true;
    });
  }

  onBeforeMount(() => {
    initializeData();
  });

  return {
    dataLoading,
    dataInitialized,
    loadData,
  }
}
