import {defineStore} from "pinia";
import {User, UserRole} from "../../Services/Types";
import {ref} from "vue";
import axios from "axios";
import {routes} from "../../Services/Routes";
import {DataLoading, useDataLoading} from "../composables/dataLoading";

type UserStoreState = DataLoading & {
  user?: User
  userRoles: UserRole[]
}

export const useUserStore = defineStore('user', (): UserStoreState => {
  const user = ref<User>();
  const userRoles = ref<UserRole[]>([]);

  const loadStoreData = async () => {
    axios.get(routes.api.internal.user.info).then(r => {
      const data = r.data as { user: User, userRoles: UserRole[] };

      user.value = data.user;
      userRoles.value = data.userRoles;
    });
  }

  const {dataLoading, dataInitialized, loadData} = useDataLoading(loadStoreData);

  return {
    user,
    userRoles,
    dataInitialized,
    dataLoading,
    loadData
  }
});
