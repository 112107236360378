<script setup lang="ts">
import {ref} from "vue";
import axios from "axios";
import Loader from "../Loader/Loader.vue";
import {routes} from "../../Services/Routes";
import { useForm } from '../Form/composables/useForm'
import FormInput from '../Form/FormInput.vue'
import FormSubmitButton from '../Form/FormSubmitButton.vue'
import UserProfileFormRow from './Form/UserProfileFormRow.vue'

interface UserChangePasswordFormProps {
}

interface FormData {
  old_password: string,
  new_password: string,
  new_password2: string,
}

const props = defineProps<UserChangePasswordFormProps>();

const {form} = useForm<FormData>({
  old_password: '',
  new_password: '',
  new_password2: '',
}, {
  validate: (values) => {
    if (values.new_password.length < 6) {
      formResponse.value = {
        success: false,
        message: 'Пароль повинен містити не менше 6 символів',
      };
      return false;
    }

    if (values.new_password !== values.new_password2) {
      formResponse.value = {
        success: false,
        message: 'Паролі не співпадають',
      };
      return false;
    }

    return true;
  },
  onSubmit: (values) => {
    savingForm.value = true;

    axios.postForm(routes.api.internal.user.change_password, values).then(response => {
      const status = response.data.status || false;
      const result = response.data.result || 'Пароль змінено';

      formResponse.value = {
        success: status,
        message: result,
      };
    }).catch(error => {
      const status = error.response.data.status || false;
      const result = error.response.data.result || 'Помилка серверу. Будь ласка, спробуйте пізніше';

      formResponse.value = {
        success: status,
        message: result,
      };
    }).finally(() => {
      savingForm.value = false;
    });
  },
});

const savingForm = ref(false);
const formResponse = ref<{
  success: boolean,
  message: string,
}>({
  success: false,
  message: '',
});
</script>

<template>
  <div class="mt-15 pt-15 border-top">
    <form @submit.prevent="form.submit()">
      <h5 class="mb-10">Якщо Ви бажаєте змінити пароль:</h5>

      <user-profile-form-row label="Старий пароль:">
        <form-input
          type="password"
          required
          name="current_password"
          v-model="form.old_password"
        />
      </user-profile-form-row>

      <user-profile-form-row label="Новий пароль:">
        <form-input
          type="password"
          required
          name="new_password"
          v-model="form.new_password"
        />
      </user-profile-form-row>


      <user-profile-form-row label="Підтвердження:">
        <form-input
          type="password"
          required
          name="new_password2"
          v-model="form.new_password2"
        />
      </user-profile-form-row>

      <div class="submit-wrap">
        <form-submit-button
          value="Зберегти зміни"
        />

        <loader :show-loader="savingForm"/>

        <span
          v-if="formResponse"
          :class="{
            'text-danger': !formResponse.success,
            'ml-15': true,
            'small': true,
          }"
        >
          {{ formResponse.message }}
        </span>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>
