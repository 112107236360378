<script setup lang="ts">
import {userHasRole} from "../../Services/User/Roles";
import {ref} from "vue";
import {UserRole} from "../../Services/Types";
import Loader from "../Loader/Loader.vue";
import {routes} from "../../Services/Routes";
import axios from "axios";

interface UserEmailConfirmProps {
  userRoles: UserRole[],
  email: string,
}

const props = defineProps<UserEmailConfirmProps>();

const sendingAnotherEmail = ref<boolean>(false);
const response = ref<{
  success: boolean,
  message: string,
}>();

const sendAnotherEmail = () => {
  if (sendingAnotherEmail.value || response.value) {
    return false;
  }

  sendingAnotherEmail.value = true;

  const showError = () => {
    response.value = {
      success: false,
      message: 'Помилка. Будь ласка, спробуйте пізніше.',
    }
  };

  axios.get(routes.api.internal.user.send_another_email)
    .then(r => {
      if (!r.data.status) {
        throw new Error('No status provided');
      }

      response.value = {
        success: true,
        message: 'Ми вислали вам новий лист для підтвердження.',
      };
    })
    .catch(() => {
      showError();
    })
    .finally(() => {
      sendingAnotherEmail.value = false;
    });
}
</script>

<template>
  <div class="col-2 ml-15 small">
    <div v-if="!props.email">
      Будь ласка, заповніть свій е-мейл
    </div>

    <div
      v-else-if="!userHasRole(userRoles, 'email_confirm')"
      class="email-unconfirmed"
    >
      <i class="icon-warning mr-5 text-warning"></i>
      Для підтвердження своєї електронної адреси, будь ласка,
      перейдіть по посиланню, яке ми прислали Вам у листі.

      <a
        href="#"
        v-if="!sendingAnotherEmail && !response"
        @click.prevent="sendAnotherEmail"
      >
        Вислати ще раз
      </a>


      <loader :show-loader="sendingAnotherEmail"></loader>

      <div
        v-if="response"
        :class="{'text-danger': !response.success, 'text-primary': response.success}"
      >
        {{ response.message }}
      </div>
    </div>

    <div v-else>
      <i class="icon-accept mr-5 text-primary"></i>
      Підтверджений e-mail.
    </div>
  </div>
</template>

<style scoped>

</style>
