export const routes = {
  api: {
    internal: {
      user: {
        info: '/api/internal/user/info',
        update: '/api/internal/user/edit',
        change_password: '/api/internal/user/change-password',
        send_another_email: '/api/internal/user/send-another-email',
      }
    }
  },
  profile: {
    ping: '/me/ping',
    settings: {
      show: '/me',
    },
    payments: {
      show: '/me/payments',
      get_invoice: '/me/payments/get-invoice',
    },
    clarity_explorer: '/me/clarity-explorer',
    accounts: {
      show: '/me/accounts',
      create: '/me/accounts/create',
    },
    promocode: '/me/promocode',
  }
}
